import {
    SET_FILTER_AVAILABILITY,
    SET_FILTER_PRICE_RANGE,
    SET_FILTER_CATEGORIES,
    SET_FILTER_TYPE
} from '../actions/filters';

import updateObject from "../updateObject";

const initialState = {
    availability: {},
    priceRange: [0,40],
    categories: [],
    type: "none"
};

export function SetFilterAvailability(state, action) {
    return updateObject(state, {
        availability: action.item
    });
}

export function SetFilterPriceRange(state, action) {
    return updateObject(state, {
        priceRange: action.item
    });
}

export function SetFilterCategories(state, action) {
    return updateObject(state, {
        categories: action.item
    });
}

export function SetFilterType(state, action) {
    return updateObject(state, {
        type: action.item
    });
}


export function filtersReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FILTER_AVAILABILITY: return SetFilterAvailability(state, action);
        case SET_FILTER_PRICE_RANGE: return SetFilterPriceRange(state, action);
        case SET_FILTER_CATEGORIES: return SetFilterCategories(state, action);
        case SET_FILTER_TYPE: return SetFilterType(state, action);

        default: return state;
    }
}