import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { addToCart, removeFromCart } from "../../redux/actions/cart";

const NavCart = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.data);
  const products = useSelector((state) => state.products);

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (!products.inLoading && cart?.length > 0) {
        let totalPrice = 0;

        cart?.map((item, index) => {
            const product = products?.data?.find(product => product?.id === item.id);

            if (product) {
                if (product.promo > 0) {
                    totalPrice += item.amount * Math.floor((product.price - product.price * product.promo / 100) * 100) / 100;
                } else {
                    totalPrice += item.amount * product.price;
                }
            }
        })

        setTotalPrice(totalPrice);
    }
}, [products.inLoading, cart]);

  return show ? (
    <div className="nav__menu">
      <div
        className="nav__menu__overlay"
        id="nav__menu__overlay"
        onClick={onClose}
      ></div>

      <div className="nav__menu__content" id="nav__menu__content">
        <div className="d-flex flex-row justify-content-between p-3 border-bottom py-0 pt-3">
          <h1 className="fs-6 text-uppercase text-black m-0">Your Shopping Cart</h1>
          <img
            src={require("../../assets/icons/close.svg").default}
            alt="Close"
            className="nav__menu__close"
            onClick={onClose}
          />
        </div>
        {cart?.length > 0 ? (
          <>
            {cart?.map((item, key) => {
              const product = products?.data?.find(
                (product) => product?.id === item?.id
              );

              if (product === undefined) {
                return;
              }

              if (product?.stock === 0) {
                dispatch(removeFromCart(item.id, item.content));
                return;
              }

              return (
                <Link className="cart__product p-3" key={key} to={`/product?id=${product?.id}`}>
                  <img
                    className="cart__product__image"
                    src={JSON.parse(product?.images)[0]}
                    alt={product?.name}
                  />
                  <div className="cart__product__info">
                    <div>
                      {product?.title} {JSON.parse(item?.content)?.color}
                    </div>
                    <div>{JSON.parse(item.content).size}</div>
                    <div>{item.amount} pcs.</div>
                  </div>

                  <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                    <div
                      className="fs-4"
                      onClick={() =>
                        dispatch(removeFromCart(item.id, item.content))
                      }
                    >
                      <span class="icon pointer material-icons-outlined">
                        remove
                      </span>
                    </div>
                    <div>
                      {JSON.parse(product?.content)?.promo > 0 ? (
                        <>
                          <strike>
                            {(item.amount * product.price).toLocaleString(
                              "ro-RO",
                              { style: "currency", currency: "RON" }
                            )}
                          </strike>
                          <br />
                          {(
                            Math.floor(
                              (item.amount * product.price -
                                (item.amount *
                                  product?.price *
                                  JSON.parse(product.content)?.promo) /
                                100) *
                              100
                            ) / 100
                          ).toLocaleString("ro-RO", {
                            style: "currency",
                            currency: "RON",
                          })}
                        </>
                      ) : (
                        <>
                          {(item.amount * product.price).toLocaleString(
                            "ro-RO",
                            { style: "currency", currency: "RON" }
                          )}
                        </>
                      )}
                    </div>
                    <div
                      className="fs-4"
                      onClick={() =>
                        dispatch(addToCart(item.id, item.content, 1))
                      }
                    >
                      <span class="icon pointer material-icons-outlined">
                        add
                      </span>
                    </div>
                  </div>
                </Link>
              );
            })}
            <div className="mx-4">
              Subtotal {(totalPrice).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
            </div>

            <Link to="/checkout" onClick={() => onClose()}>
              <div className="button m-4">Checkout</div>
            </Link>
          </>
        ) : (
          <div className="p-4">
            <center>
              The shopping cart is empty, check our{" "}
              <Link className="text-danger" to="/products">
                products
              </Link>
              .
            </center>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default NavCart;
