import axios from 'axios';

export const SET_CART = 'SET_CART';
export const ADD_ITEM_CART = 'ADD_ITEM_CART';
export const REMOVE_ITEM_CART = 'REMOVE_ITEM_CART';
export const REMOVE_ITEM_ALL_CART = 'REMOVE_ITEM_ALL_CART';

export function setCart(payload) {
    return {
        type: SET_CART,
        payload: payload
    };
}

export function addItem(item, content, amount) {
    return {
        type: ADD_ITEM_CART,
        item: item,
        amount: amount,
        content: content
    };
}

export function removeItem(item, content) {
    return {
        type: REMOVE_ITEM_CART,
        item: item,
        content: content,
    };
}

export function removeAllItems() {
    return {
        type: REMOVE_ITEM_ALL_CART
    };
}

export const fetchCart = () => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_API_KEY}/carts?token=${localStorage.getItem("token")}`)
        .then(res => {
            dispatch(setCart(res.data));
        }).catch(err => {
            console.log(err?.response?.data?.error?.message);
        });
    };
}

export const addToCart = (id, content, amount) => {
    return dispatch => {
        if (localStorage.getItem("visitor") === "true") {
            dispatch(addItem(id, content, amount));
        }
        else {
            console.log("user adds product to cart")
            axios.post(`${process.env.REACT_APP_API_KEY}/carts`,
            {
                product_id: id,
                product_content: JSON.stringify(content),
                amount: amount
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem("token")}`
                }
            })
            .then(res => {
                dispatch(addItem(id, content, amount));
            }).catch(err => {
                console.log(err?.response?.data?.error?.message);
            });
        }
    };
}

export const removeFromCart = (id, content) => {
    return dispatch => {
        if (localStorage.getItem("visitor") === "true") {
            dispatch(removeItem(id, content));
        }
        else {
            axios.delete(`${process.env.REACT_APP_API_KEY}/carts`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem("token")}`
                },
                data: {
                    product_id: id,
                    product_content: JSON.stringify(content),
                }
            })
            .then(res => {
                dispatch(removeItem(id, content));
            }).catch(err => {
                console.log(err.response.data.error.message);
            });
        }
    };
}

export const clearCart = () => {
    return dispatch => {
        if (localStorage.getItem("visitor") === "true") {
            dispatch(removeAllItems());
        }
        else {
            axios.delete(`${process.env.REACT_APP_API_KEY}/carts`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem("token")}`
                },
                data: {}
            })
            .then(res => {
                dispatch(removeAllItems());
            }).catch(err => {
                console.log(err.response.data.error.message);
            });
        }
        dispatch(removeAllItems());
    };
}