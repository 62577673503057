import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Logo from "../../assets/logo.png";
import LogoProject13 from "../../assets/logo-project13.png";

const Header = () => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.categories.data);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <footer>
            <div className="content">
                <Link to="/" className="d-flex flex-row justify-content-center align-items-center py-2 gap-1">
                    <img
                        className="logo"
                        src={Logo}
                        alt="logo Evis :)"
                    />
                </Link>

                <div className={isMobile ? "d-flex flex-column justify-content-center text-center gap-3" : "d-flex flex-row justify-content-center gap-5"}>
                    <div className="d-flex flex-column justify-content-start m-auto mt-0">
                        <div className="color--black">SUPORT CLIENTI.</div>
                        <Link to="/tos">Termeni si condiții.</Link>
                        <Link to="/shipping">Informații Transport.</Link>
                        <Link to="/returns">Informații Retur.</Link>
                        <a href="https://anpc.ro/" target="blank_">Redirecționare catre ANPC.</a>
                        <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO" target="blank_">Redirecționare catre SOL.</a>
                    </div>
                    <div className="d-flex flex-column justify-content-start m-auto mt-0">
                        <div className="color--black">PAGINA NOASTRA.</div>
                        <Link to="/about" className="color--gray">Despre noi.</Link>
                        <Link to="/contact" className="color--gray">Contactează-ne.</Link>
                    </div>
                    <div className="d-flex flex-column justify-content-start m-auto mt-0">
                        <div className="color--black">SOCIAL MEDIA.</div>
                        <a className="color--gray" href="https://www.instagram.com/evis_kids/" target="blank_">Vizitează-ne pe Instagram.</a>
                        <a className="color--gray" href="https://www.facebook.com/Evis-kids-106507764937674/" target="blank_">Vizitează-ne pe Facebook.</a>
                    </div>
                </div>
            </div>

            <div class="disclaimer">
                <a href="https://ctrl13.ro">
                    <img src={LogoProject13} alt="project13" class="logo" />
                </a>

                <a href="https://project13.ro">project13.ro</a>
            </div>

            <div className="copyright">
                <div className="text-center">
                    EVIS KIDS SRL  &copy; 2021 Evi's Kids
                </div>
            </div>
        </footer>
    );
}

export default Header;