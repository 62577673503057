import React, { useEffect, useRef, useState } from 'react'

export const Image = ({ className, src, alt, onClick }) => {
  const ref = useRef(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if(ref.current){
      if (!loaded && className) {
        ref.current.className=`image--loading ${className}`;
      }
      else {
        ref.current.className=className;
      }
    }
  }, [loaded]);

  return (
    src && alt &&
    <img ref={ref} loading='lazy' onLoad={() => setLoaded(true)} src={src} alt={alt} onClick={(e) => {if (onClick) onClick(e)}} />
  );
}

export default Image;