import React, { Suspense, lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { Header, Footer } from "../common";
import Whatsapp from "../common/Whatsapp";

import { checkIn, checkInVisitor } from "../../redux/actions/account";
import { fetchCart } from "../../redux/actions/cart";
import { fetchPage } from "../../redux/actions/page";
import { fetchProducts } from "../../redux/actions/products";
import { fetchWishlist } from "../../redux/actions/wishlist";
import { fetchCategories } from "../../redux/actions/categories";

import { Loader } from "../common/utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const About = lazy(() => delayForClient(import("./About")));
const Account = lazy(() => delayForClient(import("./Account")));
const Checkout = lazy(() => delayForClient(import("./Checkout")));
const Contact = lazy(() => delayForClient(import("./Contact")));
const Edit = lazy(() => delayForClient(import("./Edit")));
const Home = lazy(() => delayForClient(import("./Home")));
const Insert = lazy(() => delayForClient(import("./Insert")));
const Login = lazy(() => delayForClient(import("./Login")));
const Promocodes = lazy(() => delayForClient(import("./Promocodes")));
const Order = lazy(() => delayForClient(import("./Order")));
const Orders = lazy(() => delayForClient(import("./Orders")));
const Privacy = lazy(() => delayForClient(import("./Privacy")));
const Product = lazy(() => delayForClient(import("./Product")));
const Products = lazy(() => delayForClient(import("./Products")));
const Register = lazy(() => delayForClient(import("./Register")));
const Return = lazy(() => delayForClient(import("./Return")));
const TOS = lazy(() => delayForClient(import("./TOS")));
const Shipping = lazy(() => delayForClient(import("./Shipping")));
const GDPR = lazy(() => delayForClient(import("./GDPR")));
const Gallery = lazy(() => delayForClient(import("./Gallery")));
const Cart = lazy(() => delayForClient(import("./Cart")));
const Wishlist = lazy(() => delayForClient(import("./Wishlist")));

function delayForClient(promise) {
    return new Promise(resolve => {
        setTimeout(resolve, 2500);
    }).then(() => promise);
}


const Application = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (localStorage.getItem("token") !== null && localStorage.getItem("visitor") !== "true") {
            dispatch(checkIn());
            dispatch(fetchCart());
            dispatch(fetchWishlist());
        }
        else {
            dispatch(checkInVisitor());
        }

        dispatch(fetchProducts());
        dispatch(fetchPage());
        dispatch(fetchCategories());
    }, []);

    return (
        <Suspense fallback={<Loader />}>
            <Header />

            <Switch>
                <Route exact path="/about">
                    <About />
                </Route>
                <Route exact path="/account">
                    <Account />
                </Route>
                <Route exact path="/contact">
                    <Contact />
                </Route>
                <Route exact path="/cart">
                    <Cart />
                </Route>
                <Route exact path="/gallery">
                    <Gallery />
                </Route>
                <Route exact path="/edit">
                    <Edit />
                </Route>
                <Route exact path="/privacy">
                    <Privacy />
                </Route>
                <Route exact path="/tos">
                    <TOS />
                </Route>
                <Route exact path="/gdpr">
                    <GDPR />
                </Route>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/register">
                    <Register />
                </Route>
                <Route exact path="/returns">
                    <Return />
                </Route>
                <Route exact path="/shipping">
                    <Shipping />
                </Route>
                <Route exact path="/insert">
                    <Insert />
                </Route>
                <Route exact path="/promocodes">
                    <Promocodes />
                </Route>
                <Route exact path="/products">
                    <Products />
                </Route>
                <Route exact path="/produse">
                    <Products />
                </Route>
                <Route exact path="/produs">
                    <Product />
                </Route>
                <Route exact path="/product">
                    <Product />
                </Route>
                <Route exact path="/order">
                    <Order />
                </Route>
                <Route exact path="/orders">
                    <Orders />
                </Route>
                <Route exact path="/checkout">
                    <Checkout />
                </Route>
                <Route exact path="/wishlist">
                    <Wishlist />
                </Route>
                <Route exact path="/">
                    <Home />
                </Route>
            </Switch>
            <Footer />
        </Suspense>
    );
};

export default Application;