import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Index = ({ children, className, background }) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        background ?
            <div className={`layout ${className ? " " + className : ""} background--stars rounded-0 py-5`}>
                <div className="contact__container background--stars rounded rounded-5 mx-auto py-5">
                    <div className="content content--center text-center">
                        {children}
                    </div>
                </div>
            </div>
            :
            <div className={`layout ${className ? " " + className : ""}`}>
                {children}
            </div>
    )
};

export default Index;