import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import NavMenu from "./NavMenu";
import { useDispatch, useSelector } from "react-redux";
import NavCart from "./NavCart";
import NavWishlist from "./NavWishlist";


import Logo from "../../assets/logo.png";
import LogoBG from "../../assets/logo_clouds.png";
import Home from "../../assets/icons/home.svg";
import Contact from "../../assets/icons/contact.svg";
import About from "../../assets/icons/about.svg";
import Catalog from "../../assets/icons/products.svg";


const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const categories = useSelector((state) => state.categories.data);
  const cart = useSelector((state) => state.cart.data);
  const wishlist = useSelector((state) => state.wishlist.data);
  const user = useSelector((state) => state.account.data);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1440;
  const [showMenu, setShowMenu] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showWishlist, setShowWishlist] = useState(false);

  const handleMenu = (e) => {
    e?.preventDefault();
    setShowMenu(!showMenu);
  };

  const handleCart = (e) => {
    e?.preventDefault();
    setShowCart(!showCart);
  };

  const handleWishlist = (e) => {
    e?.preventDefault();
    setShowWishlist(!showWishlist);
  };

  const getCartLength = () => {
    let length = 0;

    cart?.length > 0 &&
      cart?.map((item) => {
        length += item.amount;
      });
    return length;
  };

  return (
    <header>
      <div className="d-flex flex-row justify-content-between w-100">
        {!isMobile ? (
          <>
            <div className="d-flex flex-row justify-content-between w-100">

              <div className="title">
                  <Link to="/">
                      <div className="logo">
                          <img className="logo__img" src={Logo} alt="Logo" />
                          <img className="logo__bg" src={LogoBG} alt="Logo" />
                      </div>
                  </Link>
              </div>

              <div className="nav my-auto">
                <Link className="nav__option" to="/">
                    <img className={location.pathname == "/" ? "nav__option__img color-green" : "nav__option__img"} src={Home} alt="home" />
                    <div className="nav__option__text">ACASA</div>
                </Link>
                <Link className="nav__option" to="/about">
                    <img className={location.pathname == "/about" ? "nav__option__img color-green" : "nav__option__img"} src={About} alt="about" />
                    <div className="nav__option__text">DESPRE NOI</div>
                </Link>
                <Link className="nav__option" to="/products">
                    <img className={location.pathname == "/products" ? "nav__option__img color-green" : "nav__option__img"} src={Catalog} alt="catalog" />
                    <div className="nav__option__text"> PRODUSE</div>
                </Link>
                <Link className="nav__option" to="/contact">
                    <img className={location.pathname == "/contact" ? "nav__option__img color-green" : "nav__option__img"} src={Contact} alt="contact" />
                    <div className="nav__option__text">CONTACT</div>
                </Link>
              </div>

              <div className="my-auto d-flex flex-row justify-content-center gap-4">
                <NavCart show={showCart} onClose={handleCart} />
                <NavWishlist show={showWishlist} onClose={handleWishlist} />

                <Link className="nav__button" to="/cart">
                  <img
                    className="nav__button__img"
                    src={require("../../assets/icons/cart.svg").default}
                    alt="My shopping cart."
                  />
                  <div className="nav__button__count">
                    {getCartLength(cart)}
                  </div>
                  <div className="nav__button__text">COS</div>
                </Link>
                <Link className="nav__button" to="/wishlist">
                  <img
                    className="nav__button__img"
                    src={require("../../assets/icons/wishlist.svg").default}
                    alt="Wishlist."
                  />
                  <div className="nav__button__count">
                    {Object.keys(wishlist)?.length}
                  </div>
                  <div className="nav__button__text">FAVORITE</div>
                </Link>

                <Link
                  className="nav__button"
                  to={
                    JSON.stringify(user?.data) != "{}" ? "/account" : "/login"
                  }
                >
                  <img
                    className="nav__button__img"
                    src={require("../../assets/icons/account.svg").default}
                    alt="My account."
                  />
                  <div className="nav__button__text">CONT</div>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <NavMenu show={showMenu} onClose={handleMenu} />
            <NavCart show={showCart} onClose={handleCart} />
            <NavWishlist show={showWishlist} onClose={handleWishlist} />


            <div className="nav__button p-1 w-25 d-flex flex-row justify-content-start" onClick={handleMenu}>
              <img
                className="nav__button__img"
                src={require("../../assets/icons/menu.svg").default}
                alt="My menu."
              />
            </div>

            <Link to="/" className="d-flex flex-row justify-content-center align-items-center py-2 gap-1">
              <img
                className="logo"
                src={Logo}
                alt="logo Evis :)"
              />
            </Link>

            <div className="d-flex flex-row justify-content-end gap-2 w-25">
              <Link className="nav__button p-1" to="/cart">
                <img
                  className="nav__button__img"
                  src={require("../../assets/icons/cart.svg").default}
                  alt="My shopping cart."
                />
                <div className="nav__button__count">
                  {getCartLength(cart)}
                </div>
              </Link>

              <Link className="nav__button p-1" to="/wishlist">
                <img
                  className="nav__button__img"
                  src={require("../../assets/icons/wishlist.svg").default}
                  alt="Wishlist."
                />
                <div className="nav__button__count">
                  {Object.keys(wishlist)?.length}
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </header >
  );
};

export default Header;