import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "./utils";

import Logo from "../../assets/logo.png";

const NavChoiceDropdown = ({ title, choices, close }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const categories = useSelector((state) => state.categories.data);

  return (
    <div className="nav__menu__choice">
      {
        show ?
          <>
            <div className="d-flex flex-row justify-content-between mx-4 my-2" onClick={() => setShow(!show)}>
              <div className="nav__menu__choice__title fs-6 w-fit">
                {title}
              </div>
              <span className="nav__menu__choice__icon--open material-icons-outlined">keyboard_arrow_up</span>
            </div>
            <div className="d-flex flex-column pb-3 ps-4 gap-2 nav__menu__choice__content">
              {categories
                .filter((value) => value.type == "primary")
                .map((category, index) => (
                  <Link
                    key={index}
                    to={`/configurator`}
                  >
                    <span className="icon material-icons-outlined">keyboard_arrow_right</span> {category?.title}
                  </Link>
                ))}
            </div>
          </>
          :
          <div className="d-flex flex-row justify-content-between py-2 px-4" onClick={() => setShow(!show)}>
            <div className="fs-6 w-fit nav__menu__choice__title">
              {title}
            </div>

            <span className="nav__menu__choice__icon--close material-icons-outlined">keyboard_arrow_down</span>
          </div>
      }

    </div>
  );
};

const NavMenu = ({ show, onClose }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.account.data);
  const categories = useSelector((state) => state.categories.data);
  const cart = useSelector((state) => state.cart.data);
  const wishlist = useSelector((state) => state.wishlist.data);

  const getCartLength = () => {
    let length = 0;

    cart?.length > 0 &&
      cart?.map((item) => {
        length += 1;
      });
    return length;
  };

  return show ? (
    <div className="nav__menu">
      <div
        className="nav__menu__overlay"
        id="nav__menu__overlay"
        onClick={onClose}
      ></div>

      <div className="nav__menu__content" id="nav__menu__content">
        <div className="d-flex flex-row justify-content-center p-3 py-0 pt-3">
          <Link to="/" className="d-flex flex-row">
            <Image
              className="nav__menu__logo"
              src={Logo}
              alt="Logo."
            />
          </Link>
        </div>

        <hr />


        <div className="w-100 pb-2 ps-4">
          <Link className="fs-6 w-fit text-black" to="/" onClick={() => onClose()}>
            Home
          </Link>
        </div>

        <div className="w-100 pb-2 ps-4">
          <Link className="fs-6 w-fit text-black" to="/about" onClick={() => onClose()}>
            Despre noi
          </Link>
        </div>

        <div className="w-100 pb-2 ps-4">
          <Link className="fs-6 w-fit text-black" to="/contact" onClick={() => onClose()}>
            Contacteaza-ne
          </Link>
        </div>

        <div className="w-100 pb-2 ps-4">
          <Link className="fs-6 w-fit text-black" to="/products" onClick={() => onClose()}>
            Produse
          </Link>
        </div>

        <hr />

        {
          localStorage.getItem("visitor") !== "true" ?
            <>
              <div className="w-100 pb-2 ps-4">
                <Link className="fs-6 w-fit text-black" to="/account" onClick={() => onClose()}>
                  Contul meu
                </Link>
              </div>
              <div className="w-100 pb-2 ps-4">
                <Link className="fs-6 w-fit text-black" to="/orders" onClick={() => onClose()}>
                  Comenzile mele
                </Link>
              </div>
              {
                user?.type == "master" &&
                <>

                  <div className="w-100 pb-2 ps-4">
                    <Link className="fs-6 w-fit text-black" to="/insert" onClick={() => onClose()}>
                      Adaugă produs
                    </Link>
                  </div>
                  <div className="w-100 pb-2 ps-4">
                    <Link className="fs-6 w-fit text-black" to="/promocodes" onClick={() => onClose()}>
                      Coduri promotionale
                    </Link>
                  </div>
                </>
              }
            </>
            :
            <>
              <div className="w-100 pb-2 ps-4">
                <Link className="fs-6 w-fit text-black" to="/login" onClick={() => onClose()}>
                  Autentificare
                </Link>
              </div>
              <div className="w-100 pb-2 ps-4">
                <Link className="fs-6 w-fit text-black" to="/register" onClick={() => onClose()}>
                  Înregistrare
                </Link>
              </div>
            </>
        }
        <Image
          src={require("../../assets/icons/close.svg").default}
          alt="Close"
          className="nav__menu__close"
          onClick={onClose}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default NavMenu;
