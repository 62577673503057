import {
    SET_WISHLIST,
    ADD_ITEM_WISHLIST,
    REMOVE_ITEM_WISHLIST,
    REMOVE_ITEM_ALL_WISHLIST
} from '../actions/wishlist';

import updateObject from "../updateObject";

const initialState = {
    data: localStorage.getItem("wishlist") ? JSON.parse(localStorage.getItem("wishlist")) : []
};

export function set(state, action) {
    if (!(JSON.stringify(action.payload).includes("content"))) {
        localStorage.setItem("wishlist", JSON.stringify([]));
        window.dispatchEvent( new Event('storage') );
        
        return {data: []};
    }

    localStorage.setItem("wishlist", JSON.stringify(action.payload));
    window.dispatchEvent( new Event('storage') );
    return updateObject(state, {
        data: action.payload,
    });
}

export function addItem(state, action) {
    if (state.data.find(product => product.id == action.item && JSON.stringify(product.content) == JSON.stringify(action.content)) == undefined) {
        state.data.push({id: action.item, content: action.content});
    }

    localStorage.setItem("wishlist", JSON.stringify(state.data));
    window.dispatchEvent( new Event('storage') );
    return updateObject(state, {
        data: [...state.data],
    });
}

export function RemoveItem(state, action) {
    const data = state.data.filter(product => product.id != action.item && product.content != action.content);

    localStorage.setItem("wishlist", JSON.stringify(data));
    window.dispatchEvent( new Event('storage') );
    return updateObject(state, {
        data: [...data],
    });
}

export function RemoveAllItems(state, action) {
    localStorage.setItem("wishlist", JSON.stringify([]));
    window.dispatchEvent( new Event('storage') );
    return [];
}

export function wishlistReducer(state = initialState, action) {
    switch (action.type) {
        case SET_WISHLIST: return set(state, action);
        case ADD_ITEM_WISHLIST: return addItem(state, action);
        case REMOVE_ITEM_WISHLIST: return RemoveItem(state, action);
        case REMOVE_ITEM_ALL_WISHLIST: return RemoveAllItems(state, action);
        default: return state;
    }
}