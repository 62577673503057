import React from "react";
import Image from "./Image";
import Logo from "../../../assets/logo.png";

const Loader = () => {
  return (
    <div className="loader__parent">
      <Image
        className="logo"
        src={Logo}
        alt="Logo."
      />
    </div>
  );
};

export default Loader;