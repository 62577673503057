import axios from "axios";
import jsSHA from 'jssha';

const { MD5 } = require("crypto-js");
const CryptoJS = require("crypto-js");

function encode(myString) {
  const encodedWord = CryptoJS.enc.Utf8.parse(myString); // encodedWord Array object
  const encoded = CryptoJS.enc.Base64.stringify(encodedWord); // string: 'NzUzMjI1NDE='
  return encoded;
}

export const CHECKOUT_STARTING = 'CHECKOUT_STARTING';
export const CHECKOUT_SUCCESSFULL = 'CHECKOUT_SUCCESSFULL';
export const CHECKOUT_FAILED = 'CHECKOUT_FAILED';

export function checkoutStarting(payload) {
    return {
        type: CHECKOUT_STARTING,
        payload: payload
    };
}

export function checkoutSuccessfull(payload) {
    return {
        type: CHECKOUT_SUCCESSFULL,
        payload: payload
    };
}

export function checkoutFailed(payload) {
    return {
        type: CHECKOUT_FAILED,
        payload: payload
    };
}

class Librapay {
  form 		= document.getElementById("PaymentForm");
  merch_name = "EVI'S KIDS SRL";
  merch_url 	= "eviskids.ro";
  merch_email 		= "tuiaflorentina@yahoo.com";
  key 		= "4bbfa3ab5f982ef8789f5592441267f3";
  merchant 	= "000000088003431";
  terminal 	= "88003431";
  backref 	= "https://eviskids.ro/order/";
  
  email 		= "";
  amount 	= "0.00";
  currency 	= "RON";
  order		= "";
  desc 		= "";
  trtype 	= 0; 
  merch_gmt 	= "-";
  psign 		= "";
  string 	= "";
  timestamp 	= "";
  nonce 		= "";
  
  getNonce = () => {
    this.nonce = String(MD5(String("eviskidskey_") + String(Math.floor(Math.random() * 9999999 + 99999)))).toLocaleUpperCase();

    return this.nonce; 
  }

  getTimestamp = () => {
    this.timestamp += String(new Date().getFullYear());
    this.timestamp += String((new Date().getMonth()+1) < 10 ? "0" + String((new Date().getMonth()+1)) : String((new Date().getMonth()+1)));
    this.timestamp += String(new Date().getDate() < 10 ? "0" + String(new Date().getDate()) : String(new Date().getDate()));
    this.timestamp += String(new Date().getHours() < 10 ? "0" + String(new Date().getHours()) : String(new Date().getHours()));
    this.timestamp += String(new Date().getMinutes() < 10 ? "0" + String(new Date().getMinutes()) : String(new Date().getMinutes()));
    this.timestamp += String(new Date().getSeconds() < 10 ? "0" + String(new Date().getSeconds()) : String(new Date().getSeconds()));

    return this.timestamp;
  }

  getString = () => {
    this.string = `${String(Number(this.amount).toFixed(2)) !== "-" ? String(Number(this.amount).toFixed(2)).length : "-"}${String(Number(this.amount).toFixed(2))}`
    + `${String(this.currency) !== "-" ? String(this.currency).length : "-"}${String(this.currency)}`
    + `${String(this.order) !== "-" ? String(this.order).length : "-"}${String(this.order)}`
    + `${String(this.desc) !== "-" ? String(this.desc).length : "-"}${String(this.desc)}`
    + `${String(this.merch_name) !== "-" ? String(this.merch_name).length : "-"}${String(this.merch_name)}`
    + `${String(this.merch_url) !== "-" ? String(this.merch_url).length : "-"}${String(this.merch_url)}`
    + `${String(this.merchant) !== "-" ? String(this.merchant).length : "-"}${String(this.merchant)}`
    + `${String(this.terminal) !== "-" ? String(this.terminal).length : "-"}${String(this.terminal)}`
    + `${String(this.merch_email) !== "-" ? String(this.merch_email).length : "-"}${String(this.merch_email)}`
    + `${String(this.trtype) !== "-" ? String(this.trtype).length : "-"}${String(this.trtype)}`
    + `--`
    + `${String(this.timestamp) !== "-" ? String(this.timestamp).length : "-"}${String(this.timestamp)}`
    + `${String(this.nonce) !== "-" ? String(this.nonce).length : "-"}${String(this.nonce)}`
    + `${String(this.backref) !== "-" ? String(this.backref).length : "-"}${String(this.backref)}`;

    return this.string;
  }

  getSign = () => {
    const hashObj = new jsSHA("SHA-1", "TEXT");

    hashObj.setHMACKey(this.key, "HEX");
    hashObj.update(this.string);

    this.psign = hashObj.getHMAC("HEX").toLocaleUpperCase();

    return this.psign;
  }

  setValues = (props) => {
    this.amount = props.amount;
    this.order = props.order;
    this.desc = "Comanda #" + props.order;
    this.products = props.products;
    this.email = props.email;
    this.backref += props.orderId;
  }

  fullfillForm = (props) => {
    document.getElementById("PaymentAmount").value = String(Number(this.amount).toFixed(2));
    document.getElementById("PaymentCurrency").value = this.currency;
    document.getElementById("PaymentOrder").value = this.order;
    document.getElementById("PaymentMerchName").value = this.merch_name;
    document.getElementById("PaymentMerchURL").value = this.merch_url;
    document.getElementById("PaymentMerchant").value = this.merchant;
    document.getElementById("PaymentEmail").value = this.email;
    document.getElementById("PaymentTrType").value = this.trtype;
    document.getElementById("PaymentDesc").value = this.desc;
    document.getElementById("PaymentTerminal").value = this.terminal;
    document.getElementById("PaymentTimestamp").value = this.timestamp;
    document.getElementById("PaymentNonce").value = this.nonce;
    document.getElementById("PaymentBackref").value = this.backref;
    document.getElementById("PaymentPSIGN").value = this.psign;
    document.getElementById("PaymentSTRING").value = this.string;

    document.getElementById("PaymentDataCustom").value = encode(JSON.stringify({
      ProductsData: this.products.filter(value => JSON.stringify(value) !== "{}"),
      UserData: {
        Email: props.email,
        Name: props.firstname + props.lastname,
        Phone: props.phone,
        BillingEmail: props.email,
        BillingName: props.firstname + props.lastname,
        BillingPhone: props.phone,
        BillingCity: props.city,
        BillingCountry: "ROMANIA",
        ShippingEmail: props.email,
        ShippingName: props.firstname + props.lastname,
        ShippingAddress: props.address,
        ShippingPhone: props.phone,
        ShippingCity: props.city,
        ShippingCountry: "ROMANIA"
      }
    }));
  }
}

const MakePayment = (props) => {
    let form = document.getElementById("PaymentForm");

    const orderId = String(props.insertId);
    const order = "1" + (5 - orderId.length > 0 ? "0".repeat(5 - orderId.length) + orderId : orderId);
    let productsData = [];
    let specialProductId = 999999;
    let totalPrice = 0;
        
    for (let product of JSON.parse(props.cart)) {
      productsData.push({
        ItemName: product.title,
        Quantity: product.amount,
        Price: product.price,
        ProductId: product.id
      });
    }
    
    for (let product of productsData) {
      totalPrice += product.Price * product.Quantity;
    }

    const LibrapayPayment = new Librapay();

    LibrapayPayment.setValues({
      amount: totalPrice,
      order: order,
      orderId: props.insertId,
      products: productsData,
      ...props
    });
    
    LibrapayPayment.getNonce();
    LibrapayPayment.getTimestamp();
    LibrapayPayment.getString();
    LibrapayPayment.getSign();

    LibrapayPayment.fullfillForm({
      amount: totalPrice,
      order: order,
      ...props,
      products: productsData
    });

    form.submit();
}

export const checkout = (props) => {
    return async (dispatch) => {
        dispatch(checkoutStarting());

        axios.post(`${process.env.REACT_APP_API_KEY}/orders`,
        {
            ...props
        },
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem("token")}`
            }
        })
        .then(res => {
            const request = res.data;

            if (props.paymentMethod == "librapay") {
              MakePayment({
                ...props,
                ...request
                });
            }
            else {
              const urlParams = new URLSearchParams(window.location.search);

              urlParams.set('id', request.insertId);

              window.history.pushState(null, null, "/order?" + urlParams.toString());
              window.location.reload();
            }
            
            dispatch(checkoutSuccessfull(res.data));
        }).catch(err => {
            dispatch(checkoutFailed(err?.response?.data?.error?.message || err?.message || err));
        });
    }
};

export const setPaymentSuccessful = ({id}) => {
  axios.post(`${process.env.REACT_APP_API_KEY}/payment`, {
    id: id,
    RC: "00"
  },
  {
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem("token")}`
      }
  })
  .then(res => {
  }).catch(err => {
  });
};